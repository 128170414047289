
import {
  defineComponent,
  toRaw,
  reactive,
  toRefs,
  ref,
  watch,
  UnwrapRef,
} from "vue";
import pagaTable from "@/components/pagination-table/index";
import { TransformCellTextProps } from "ant-design-vue/lib/table/interface";
import formSearch from "@/components/page-search/form";
import {
  userInfoPage,
  departmentTree,
  updatePassword,
  userToDepartment_post,
  userPut,
} from "@/api/system";
import popupWindow from "./popupNew.vue";
import popupUser from "./popupUser.vue";
import { notification } from "ant-design-vue";
import { message } from "ant-design-vue";
// import { ExaminationPaperController } from '@/api/downloadApi'
import { blob } from "@/utils/blob";
const purchaseColumns = [
  {
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row: TransformCellTextProps) => row.index + 1,
  },
  {
    title: "用户姓名",
    dataIndex: "realName",
  },
  {
    title: "角色",
    dataIndex: "roleName",
  },
  {
    title: "是否启用",
    dataIndex: "isEnabled",
    slots: { customRender: "isEnabled" },
  },
  {
    title: "操作",
    dataIndex: "operation",
    slots: { customRender: "operation" },
  },
];
interface FormState {
  password1: string;
  password2: string;
}
export default defineComponent({
  name: "questionType",
  components: {
    pagaTable,
    formSearch,
    popupWindow,
    popupUser,
  },
  setup(props: any) {
    let checkAge1 = async (value: any) => {
      const passwordreg =
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,20}/;
      if (!formState.password1) {
        return Promise.reject("请输入密码");
      } else {
        if (!passwordreg.test(formState.password1)) {
          return Promise.reject(
            "密码为8-20位,且同时包含数字、大写字母、小写字母、特殊符号"
          );
        } else {
          return true;
        }
      }
    };
    let checkAge2 = async (value: number) => {
      if (!formState.password2) {
        return Promise.reject("请输入密码");
      } else {
        if (formState.password1 != formState.password2) {
          return Promise.reject("密码不一致");
        } else {
          return true;
        }
      }
    };
    const data = reactive({
      rules: {
        password1: [{ validator: checkAge1, trigger: "blur", required: true }],
        password2: [{ validator: checkAge2, trigger: "blur", required: true }],
      },
      purchaseColumns,
      selectedRowKeys: [],
      extraModel: {},
      formBtn: {
        colProps: { span: 16 },
        formItemProps: {
          wrapperCol: {
            span: 24,
          },
        },
      },
      formConfig: [
        {
          colProps: {
            span: 8,
          },
          formItemProps: {
            label: "姓名：",
          },
          comConfig: {
            tag: "input", // input, select, date-picker
            key: "realName", // 即是插槽也是字段model key
            props: {
              placeholder: "请输入",
            },
          },
        },
      ],
    });
    const onSelectChange = (selectedRowKeys: never[]) => {
      data.selectedRowKeys = selectedRowKeys;
    };
    const table = ref();
    const params_api = ref();
    const getDataApi = (params: any) => {
      return new Promise((reslove) => {
        params_api.value = {
          currentPage: params.pageIndex,
          pageSize: params.pageSize,
          isAdmin: true,
          ...formSearch.value.getQuery(),
          departmentId: departmentId.value ? departmentId.value : "",
        };
        userInfoPage({ ...params_api.value }).then((res: unknown) => {
          reslove(res);
        });
      });
    };
    function handleDownload() {
      // ExaminationPaperController({ ...params_api.value }).then((res: any) => {
      //   blob(res)
      // })
    }
    const handleChange = (
      pagination: any,
      filter: any,
      sorter: any,
      { currentDataSource }: any
    ) => {
      console.log(pagination, filter, sorter, currentDataSource);
    };
    const formSearch = ref();
    const popupWindow = ref();
    const popupUser = ref();
    const Title = ref();
    const TitleUser = ref();
    const updatedTime = ref([]);
    const handleSearch = (formState: any) => {
      table.value.refresh();
    };
    const handleClick = (text: string, row: any) => {
      Title.value = "编辑";
      popupWindow.value.showModal(row.userId);
    };
    const selectedKeys = ref<string[]>([]);
    const departmentId = ref();
    watch(selectedKeys, () => {
      departmentId.value = selectedKeys.value;
      handleSearch({});
    });
    const value = ref<string>("");

    const onSearch = () => {
      console.log("value", value.value);
    };
    const treeData = ref([]);
    departmentTree().then((res) => {
      treeData.value = res.data;
    });
    const replaceFields = {
      children: "children",
      key: "departmentId",
      title: "departmentName",
    };
    const formState: UnwrapRef<FormState> = reactive({
      password1: "",
      password2: "",
    });
    const visible = ref(false);
    const formRef = ref();
    const ChangePassword = (record: { userId: any }) => {
      formState.password1 = "";
      formState.password2 = "";
      userId.value = record.userId;
      visible.value = true;
    };
    const userId = ref();
    const handleOk = () => {
      formRef.value
        .validate()
        .then(() => {
          console.log(toRaw(formState));
          updatePassword({
            password: formState.password1,
            userId: userId.value,
          }).then((res) => {
            visible.value = false;
            message.success("修改成功");
            handleSearch({});
          });
        })
        .catch((error: any) => {});
    };
    const departmentvisible = ref(false);
    const responsibleDepartment = ref(null);
    const departmentReplaceFields = ref({
      title: "departmentName",
      value: "departmentId",
      key: "departmentId",
      children: "children",
    });
    const department = (record: { userId: any; department: any }) => {
      userId.value = record.userId;
      if (record.department) {
        responsibleDepartment.value = record.department;
      } else {
        responsibleDepartment.value = null;
      }
      departmentvisible.value = true;
    };
    const departmentHandleOk = () => {
      if (responsibleDepartment.value) {
        userToDepartment_post({
          userId: userId.value,
          departmentId: responsibleDepartment.value,
        }).then((res) => {
          departmentvisible.value = false;
          table.value.refresh();
        });
      } else {
        notification.error({
          message: "提示",
          description: "请选择部门",
        });
      }
    };
    const handleAdd = () => {
      TitleUser.value = "新增用户";
      popupUser.value.showModal();
    };
    const clickChange = (row: any) => {
      if (row.isEnabled) {
        userPut({ isEnabled: row.isEnabled, userId: row.userId }).then(
          (res) => {
            handleSearch({});
          }
        );
      } else {
        userPut({ isEnabled: row.isEnabled, userId: row.userId }).then(
          (res) => {
            handleSearch({});
          }
        );
      }
    };
    return {
      handleDownload,
      clickChange,
      TitleUser,
      handleAdd,
      notification,
      departmentHandleOk,
      responsibleDepartment,
      departmentReplaceFields,
      departmentvisible,
      department,
      formRef,
      formState,
      handleOk,
      visible,
      ChangePassword,
      replaceFields,
      handleClick,
      value,
      onSearch,
      treeData,
      selectedKeys,
      ...toRefs(data),
      table,
      onSelectChange,
      getDataApi,
      handleChange,
      formSearch,
      popupWindow,
      popupUser,
      Title,
      handleSearch,
      updatedTime,
    };
  },
});
